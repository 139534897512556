<template>
  <div>
    <!-- Loading and Error if no speaker found -->
    <details-loading
      v-if="!speaker"
      type="Speaker"
      router-to="Speakers"
      :data="allSpeakers"
    ></details-loading>

    <!-- There is a speaker, display it -->
    <div class="row mt-4" v-else>
      <div class="col-12">
        <aad-back></aad-back>
      </div>

      <aad-title class="w-100">{{ speaker.DisplayName }}</aad-title>

      <!-- Fullscreen if there are no extra details, of col-md-8 if there are details and social links -->

      <div class="col-lg-4 p-1">
        <img
          :src="speaker.PhotoURL"
          alt="Image of Speaker"
          v-if="speaker.PhotoURL"
          class="speaker-photo"
        />
        <img
          :src="getSpeakerPicture(speaker.PersonId)"
          v-else
          :alt="'image of' + speaker.DisplayName"
          class="speaker-photo"
        />
        <div class="meeting-app-info-block" v-if="anyDetails">
          <div class="meeting-app-info-header row">
            <h4>Speaker Information</h4>
          </div>
          <div class="meeting-app-info-body">
            <div class="row meeting-app-info-item" v-if="speaker.Facebook_URL">
              <a
                :href="speaker.Facebook_URL"
                @click="
                  logLinkClick(
                    'bio_facebook',
                    speaker.Facebook_URL,
                    'speaker_details'
                  )
                "
                >Facebook</a
              >
            </div>
            <div class="row meeting-app-info-item" v-if="speaker.Twitter_URL">
              <a
                :href="twitterLink"
                @click="
                  logLinkClick('bio_twitter', twitterLink, 'speaker_details')
                "
                >Twitter
                <span v-if="speaker.Twitter_URL.startsWith('@')"
                  >- {{ speaker.Twitter_URL }}</span
                ></a
              >
            </div>
            <div class="row meeting-app-info-item" v-if="speaker.Linkedin_URL">
              <a
                :href="speaker.Linkedin_URL"
                @click="
                  logLinkClick(
                    'bio_linkedin',
                    speaker.Linkedin_URL,
                    'speaker_details'
                  )
                "
                >LinkedIn</a
              >
            </div>
            <div class="row meeting-app-info-item" v-if="speaker.Website">
              <a
                :href="speaker.Website"
                @click="
                  logLinkClick(
                    'bio_website',
                    speaker.Website,
                    'speaker_details'
                  )
                "
                >Website</a
              >
            </div>
          </div>
        </div>
      </div>

      <div class="p-1 col-lg-8">
        <div v-if="speaker.Bio" class="p-3">
          <h4>Speaker Bio:</h4>
          <p>{{ speaker.Bio }}</p>
        </div>

        <div class="meeting-app-info-block w-100">
          <div class="meeting-app-info-header row">
            <h4>Session Schedule</h4>
          </div>

          <div
            class="meeting-app-info-body"
            v-if="speaker.Sessions && speaker.Sessions.length > 0"
          >
            <!-- Slice is used to create a shallow copy of the array to prevent an infinite loop of reactivity from sort -->
            <div
              v-for="session in speaker.Sessions.slice().sort(
                (a, b) => a.StartTime.seconds - b.StartTime.seconds
              )"
              :key="session.SessionId"
            >
              <div class="row meeting-app-info-item">
                <div>
                  <router-link
                    :to="{
                      name: 'SessionDetails',
                      params: { id: session.SessionId },
                    }"
                    @click.native="
                      logNavigationToSession(
                        session.SessionId,
                        session.SessionCode
                      )
                    "
                    @click.native.middle="
                      logNavigationToSession(
                        session.SessionId,
                        session.SessionCode
                      )
                    "
                    class="text-link-light-bg"
                  >
                    <span class="font-weight-bold">
                      {{ session.SessionCode }} - {{ session.Name }}
                    </span>
                  </router-link>
                  -
                  <session-time-string
                    type="full-range"
                    :session="session"
                  ></session-time-string>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="meeting-app-info-block w-100" v-if="speaker.Disclosures">
          <div class="meeting-app-info-header row">
            <h4>Disclosures</h4>
          </div>

          <div class="meeting-app-info-body">
            <div
              class="row meeting-app-info-item"
              v-for="disclosure in speaker.Disclosures.replaceAll(
                '\&ndash\;',
                '-'
              ).split(';')"
              :key="disclosure"
            >
              <span v-if="disclosure.trim().startsWith('no financial')"
                >No financial relationships exist with ineligble
                companies.</span
              >
              <span v-else>{{ disclosure }}</span>
            </div>
          </div>
        </div>

        <!-- Handouts -->
        <div
          class="meeting-app-info-block"
          v-if="speaker.Handouts && speaker.Handouts.length > 0"
        >
          <div class="meeting-app-info-header row">
            <h4>Handouts</h4>
          </div>
          <div class="meeting-app-info-body" v-if="isLoggedIn">
            <div
              class="row meeting-app-info-item"
              v-for="handout in speaker.Handouts"
              :key="handout.HandoutURL"
            >
              <a
                :href="handout.HandoutURL"
                target="_blank"
                @click="
                  logHandoutClick(handout.HandoutTitle, handout.HandoutURL)
                "
                >{{ handout.HandoutTitle }}</a
              >
            </div>
          </div>
          <div class="meeting-app-info-body" v-else>
            <div class="row meeting-app-info-item">
              <router-link
                :to="{
                  name: 'Login',
                }"
                class="text-link-light-bg"
              >
                Login to View Handouts
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div v-if="isLoggedIn" class="w-100">
        <hr />
        <attendee-notes
          class="w-100"
          ref="notes"
          note-type="speaker"
          :link-id="speaker.id"
          :title="speaker.DisplayName"
        ></attendee-notes>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DetailsLoading from "@/components/DetailsLoading";
import SessionTimeString from "@/components/SessionTimeString";
import AttendeeNotes from "@/components/AttendeeNotes";
import Analytics from "@/mixins/analytics";

export default {
  data() {
    return {
      speakerId: this.$route.params.id,
    };
  },
  metaInfo() {
    return {
      title: this.speaker ? this.speaker.DisplayName : "Speaker Details",
    };
  },
  mounted() {
    this.getSpeakers();
  },
  beforeRouteLeave(to, from, next) {
    if (this.isLoggedIn && this.$refs.notes.hasUnsavedChanges) {
      if (confirm("You have unsaved notes, do you want to leave this page?")) {
        // said to leave anyways.
        next();
      } else {
        next(false);
      }
    } else {
      // no unsaved changes
      next();
    }
  },
  methods: {
    getSpeakers() {
      this.$store.dispatch("loadSpeakers");
    },
    getSpeakerPicture(speakerId) {
      let meetingCode = this.$store.state.meetingCode;
      let url = `https://lms.aad.org/MeetingSpeaker/GetPhoto?eventId=${meetingCode}&personId=${speakerId}`;
      return url;
    },
    logNavigationToSession(sessionId, sessionCode) {
      // Log session Click
      Analytics.logEvent("aad_session_click", {
        search_term: null,
        aad_session_id: sessionId,
        session_code: sessionCode,
        applied_filters: null,
        screen_origin: "speaker",
      });
    },
    logLinkClick(linkType, url, screenOrigin) {
      Analytics.logLinkClick(linkType, url, screenOrigin);
    },
    logHandoutClick: function (handoutTitle, handoutUrl) {
      // Log to analytics
      Analytics.logEvent("aad_handout_click", {
        file_name: handoutTitle,
        handout_url: handoutUrl,
      });
    },
  },
  components: {
    DetailsLoading,
    SessionTimeString,
    AttendeeNotes,
  },
  computed: {
    ...mapGetters([
      "allSpeakers",
      "getSpeakerById",
      "isLoggedIn",
      "getSessionById",
    ]),
    speaker() {
      return this.getSpeakerById(this.speakerId);
    },

    anyDetails() {
      return (
        this.speaker.Facebook_URL ||
        this.speaker.Linkedin_URL ||
        this.speaker.Website ||
        this.speaker.Twitter_URL
      );
    },
    twitterLink() {
      // if blank, return blank.
      if (!this.speaker.Twitter_URL) {
        return "";
      }

      // If it doesn't start with a link, make it one
      if (!this.speaker.Twitter_URL.startsWith("http")) {
        return "https://twitter.com/" + this.speaker.Twitter_URL;
      }

      return this.speaker.Twitter_URL;
    },
  },
  watch: {
    $route() {
      // react to route changes to 'refresh' on new IDs and clearing it...
      this.speakerId = this.$route.params.id;
    },
  },
};
</script>

<style scoped>
.speaker-photo {
  border-radius: 5px;
  max-width: 200px;
  width: 100%;
  margin: auto;
  display: block;
}
</style>
